import React, {useState , useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';
import {Button} from './Button';
import axios from 'axios';
import Cookie from 'js-cookie';

const handlePrivate = async e => {
    const headers = {
        authorization: `Bearer ${Cookie.get("jwt")}`
    }
    const response = await axios.get('https://lab.oretasg.com:3003/api/user/private' , { headers });

    if (response.data.message){
        console.log("message received from server");
        return true;
    }else{
        return false;
    }
    };

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
      if (window.innerWidth <= 960) {
        setButton(false);
      } else {
        setButton(true);
      }
    };

    const [access , setAccess] = useState(false)

    useEffect(() => {
        handlePrivate()
        //.then((token) => {setAccess(token)})
        .then((token) => {console.log(token); setAccess(token)})
    })

    useEffect(() => {
        showButton()
    }, []);

    window.addEventListener('resize' , showButton);

    return (
        <>
           <nav className = 'navbar'>
            <div className='navbar-container'>
                <Link to="/" className="navbar-logo" onClick = {closeMobileMenu}>
                    EEE <i className = 'fas fa-wave-square' />
                </Link>
                <div className = 'menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className = {click ? 'nav-menu active' : 'nav-menu'}>
                    <li className = 'nav-item'>
                        <Link to='/' className = 'nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className = 'nav-item'>
                        <Link to='/experiments' className = 'nav-links' onClick={closeMobileMenu}>
                            Experiments
                        </Link>
                    </li>
                    <li className = 'nav-item'>
                        <Link to='/grades' className = 'nav-links' onClick={closeMobileMenu}>
                            Grades
                        </Link>
                    </li>
                    <li className = 'nav-item'>
                        <Link to='/login' className = 'nav-links-mobile' onClick={closeMobileMenu}>
                            LOGIN
                        </Link>
                    </li>
                    :
                    <li className = 'nav-item'>
                        <Link to='/login' className = 'nav-links-mobile' onClick={closeMobileMenu}>
                            LOGOUT
                        </Link>
                    </li>
                    }
                </ul>
                {access === false ?
                <>{button && <Button buttonStyle = 'btn--outline'>LOGIN/LOGOUT</Button>}</>
                :
                <>{button && <Button buttonStyle = 'btn--outline'>LOGOUT</Button>}</>
                }
            </div>
           </nav>
        </>
    )
}

export default Navbar;
