import React, { useState } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import {Link} from 'react-router-dom';
import Cookie from 'js-cookie';
import {SignInButton} from "./msalAuth/SignInButton";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { BackEndpath } from "./APIpath";

//styling
import "../../App.css";
import "./Register.css";

function Login() {

    const isAuthenticated = useIsAuthenticated();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {instance} = useMsal();

    // sumbit changes to the backend api
    const submitHandler = async e => {
        e.preventDefault();
        axios
            // route the suitable url for deployment
            .post(`${BackEndpath}/api/user/login`, {
                email: email,
                password: password
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => {
                alert(JSON.stringify(response.data.message));
                Cookie.set("vrfy" , response.data.stat)
                Cookie.set("jwt", response.data.token);
                Cookie.set("mail_id", email)
            })
            .catch((error) => {
                //console.log(JSON.stringify(error.response));
                //alert(JSON.stringify(error.response));
            })
            .then(()=>{
                window.location.reload();
            });
    };

    const toRemove = ["jwt" , "vrfy", "mail_id", "rndmSet1" ,"rndmSet2", "rndmSet3"]

    const logoutHandler = () => {

        for (let i = 0 , len = toRemove.length; i < len ; i++){
            Cookie.remove(toRemove[i])
        }

        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        }).catch(e=>{console.log(e)});
        window.location.reload();
    }

    return (
        <div className='Register'>

            { /*logged in message */}
            {Cookie.get("vrfy") === "vfyT123%%stat" || isAuthenticated?
                <div className='fullpage'>
                    <h1>You are Logged In!</h1>
                    <div className="logcenter">
                        <button className='buttonBlack' onClick={logoutHandler}><span>Logout</span></button>
                    </div>
                </ div>
                :
                <div className='fullpage'>
                    <Form>
                        <h1>Login</h1>
                        <br></br>
                        <Form.Group size="lg" controlId="email">
                            <Form.Label>Email: </Form.Label>
                            <Form.Control
                                autoFocus
                                type="email"
                                name="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group size="lg" controlId="password">
                            <Form.Label>Password: </Form.Label>
                            <Form.Control
                                autoFocus
                                type="password"
                                name="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <div class='centered'>
                            <button className='buttonBlack' onClick={submitHandler}><span>Login</span></button>
                        </div>
                        <SignInButton />
                        <div className='logcenter'>
                            <p>Doesn't have an account? <Link to='/register'>register</Link></p>
                        </div>
                    </Form>
                </ div>
            }
        </div>
    );

}


export default Login;

