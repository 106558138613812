// Required libraries
import {React, Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
// Styling
import './App.css';
// Components and Pages for the webstie
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/pages/Home';
import Experiments from './components/pages/Experiments';
import Register from '../../frontend/src/components/pages/Register';
import Grades from './components/pages/Grades';
import Login from './components/pages/Login';
import Instruction from './components/Instruction';
import SectionA from './components/pages/ExperimentsList/SectionA';
import SectionA1 from './components/pages/ExperimentsList/subSectionA/SectionA1';
import SectionA2 from './components/pages/ExperimentsList/subSectionA/SectionA2';
import SectionA3 from './components/pages/ExperimentsList/subSectionA/SectionA3';
import SectionB from './components/pages/ExperimentsList/SectionB';
import SectionB1 from './components/pages/ExperimentsList/subSectionB/SectionB1';
import SectionB2 from './components/pages/ExperimentsList/subSectionB/SectionB2';
import SectionB3 from './components/pages/ExperimentsList/subSectionB/SectionB3';
import SectionC from './components/pages/ExperimentsList/SectionC';
import SectionC1 from './components/pages/ExperimentsList/subSectionC/SectionC1';
import SectionD from './components/pages/ExperimentsList/SectionD';
import SectionD1 from './components/pages/ExperimentsList/subSectionD/SectionD1';
import SectionD2 from './components/pages/ExperimentsList/subSectionD/SectionD2';
import SectionE from './components/pages/ExperimentsList/SectionE';
import SectionE1 from './components/pages/ExperimentsList/subSectionE/SectionE1';
import SectionF from './components/pages/ExperimentsList/SectionF';
import SectionF1 from './components/pages/ExperimentsList/subSectionF/SectionF1';
import SectionF2 from './components/pages/ExperimentsList/subSectionF/SectionF2';
import ButtonMJ from './components/ButtonMJ.js'

class App extends Component {
  render() {
   return (
    <div className="App">
     <Router>
      <Navbar />
      <ScrollToTop>
        <Switch>
          <Route path = '/' exact component = {Home}/>
          <Route path = '/experiments' component = {Experiments}/>
          <Route path = '/login' component = {Login}  />
          <Route path = '/register' component = {Register}/>
          <Route path = '/grades' component = {Grades}/>
          <Route path = '/section-a' component = {SectionA} />
          <Route path = '/section-a-1' component = {SectionA1} />
          <Route path = '/section-a-2' component = {SectionA2} />
          <Route path = '/section-a-3' component = {SectionA3} />
          <Route path = '/section-b' component = {SectionB} />
          <Route path = '/section-b-1' component = {SectionB1} />
          <Route path = '/section-b-2' component = {SectionB2} />
          <Route path = '/section-b-3' component = {SectionB3} />
          <Route path = '/section-c' component = {SectionC} />
          <Route path = '/section-c-1' component = {SectionC1} />
          <Route path = '/section-d' component = {SectionD} />
          <Route path = '/section-d-1' component = {SectionD1} />
          <Route path = '/section-d-2' component = {SectionD2} />
          <Route path = '/section-e' component = {SectionE} />
          <Route path = '/section-e-1' component = {SectionE1} />
          <Route path = '/section-f' component = {SectionF} />
          <Route path = '/section-f-1' component = {SectionF1} />
          <Route path = '/section-f-2' component = {SectionF2} />
          <Route path = '/instruction' component = {Instruction} />
        </Switch>
      </ScrollToTop>
      <Footer/>
    </Router>
    </div>
  )};
}

export default App;
